// @ts-ignore
import {RelaxedJsonListener} from '../../grammar/relaxedJson.g4';

export default class AddMissingQuoteListener extends RelaxedJsonListener {

  private result = '';
  private readonly ruleNames: string[];

  constructor(ruleNames: string[]) {
    super();
    this.ruleNames = ruleNames;
  }

  public visitTerminal(node: any) {
    const ruleName = this.ruleNames[node.getParent().ruleIndex];
    const text = node.getText();
    if (ruleName === 'stringOrIdentifier' && !text.startsWith('"')) {
      this.result += '"' + text + '"';
    } else {
      this.result += text;
    }
  }

  public getResult() {
    return this.result;
  }
}
