// @ts-ignore
import {CommonTokenStream, InputStream, tree as antlrTree} from 'antlr4/index';
// @ts-ignore
import {RelaxedJsonLexer, RelaxedJsonParser} from '../../grammar/relaxedJson.g4';
import AddMissingQuoteListener from './AddMissingQuoteListener';

export default class JsonParser {

  public static parse(s: string): object {
    const chars = new InputStream(s);
    const lexer = new RelaxedJsonLexer(chars);
    const tokens = new CommonTokenStream(lexer);
    const parser = new RelaxedJsonParser(tokens);
    const printer = new AddMissingQuoteListener(parser.ruleNames);
    parser.buildParseTrees = true;
    const tree = parser.json();
    antlrTree.ParseTreeWalker.DEFAULT.walk(printer, tree);
    const result = printer.getResult();
    return JSON.parse(result);
  }
}
