import * as React from 'react';
import withI18next from '../../../plugins/gatsby-plugin-i18next/withI18next';
import JsonParser from '../../components/jsonParser';
import Formatter from '../../components/tools/formatter';
import Layout from '../../layouts/tools';
import constants from '../../utils/constants';

const styles = require('./json.module.scss');

function formatter(data: string, indent: string) {
  let result;

  try {
    result = JSON.parse(data);
  } catch (e) {
    try {
      result = JsonParser.parse(data);
    } catch (e) {
      throw e;
    }
  }
  return JSON.stringify(result, null, indent);

}

const Page = () => (
  <Layout currentPage={constants.PAGE_JSON}>
    <Formatter
      className={styles.formatter}
      currentPage={constants.PAGE_JSON}
      language={'json'}
      format={formatter}
    />
  </Layout>
);

export default withI18next()(Page);
